<template>
    <div class="shop_features" v-if="isMenu">
        <div class="content">
            <div class="logo">
                <img src="../../../assets/img/shop_logo.png" title="易诚药材通" class="img" @click="$route.path === '/index' ? '' : $router.push('/index')" />
            </div>

            <template v-if="searchType === 1 || searchType === 4">
                <div class="search_group">
                    <div class="search_form" v-if="isSearchPage">
                        <el-input v-model="searchName" placeholder="请输入产品名称" @keyup.enter.native="toGoodSearch(true, searchName)"></el-input>
                        <el-button type="primary" @click="toGoodSearch(true, searchName)">搜索</el-button>
                    </div>
                    <div class="search_form" v-else>
                        <el-input v-model="searchElseName" :placeholder="searchDefaultData ? searchDefaultData : '请输入产品名称'" @keyup.enter.native="toGoodSearch(false, searchElseName ? searchElseName : searchDefaultData)"></el-input>
                        <el-button type="primary" @click="toGoodSearch(false, searchElseName ? searchElseName : searchDefaultData)">搜索</el-button>
                    </div>

                    <div class="search_list" v-if="searchHotData.length > 0 && isSearchPage">
                        <span class="title">热门搜索：</span>
                        <a href="javascript:void(0)" class="text" v-for="(item, index) in searchHotData" :key="index" @click="toGoodSearch(true, item.name)">{{item.showName}}</a>
                    </div>

                    <div class="search_list" v-if="searchHotData.length > 0 && !isSearchPage">
                        <span class="title">热门搜索：</span>
                        <a href="javascript:void(0)" class="text" v-for="(item, index) in searchHotData" :key="index" @click="toGoodSearch(false, item.name)">{{item.showName}}</a>
                    </div>
                </div>

                <div class="car">
                    <el-button type="primary" plain @click="goShopLogin(isLogin, '/shop/car')"><img src="../../../assets/img/my_car.png" />我的购物车</el-button>
                </div>
            </template>

            <template v-if="searchType === 2">
                <div class="search_group buying">
                    <div class="search_form" v-if="isBuyingPage">
                        <el-input v-model="buyingSearchName" placeholder="请输入您要搜索的供求订单关键字" @keyup.enter.native="toBuyingSearch(true, buyingSearchName)"></el-input>
                        <el-button type="primary" @click="toBuyingSearch(true, buyingSearchName)">搜索</el-button>
                    </div>
                    <div class="search_form" v-else>
                        <el-input v-model="buyingSearchElseName" placeholder="请输入您要搜索的供求订单关键字" @keyup.enter.native="toBuyingSearch(false, buyingSearchElseName)"></el-input>
                        <el-button type="primary" @click="toBuyingSearch(false, buyingSearchElseName)">搜索</el-button>
                    </div>
                </div>

                <div class="car buying">
                    <el-button type="primary" plain @click="goAdminLogin(isLogin, status, '/supplyBuying/buying/add')"><img src="../../../assets/img/my_buying.png" />发布采购单</el-button>
                </div>
            </template>

            <template v-if="searchType === 3">
                <div class="search_group supply">
                    <div class="search_form" v-if="isSupplyPage">
                        <el-input v-model="supplySearchName" placeholder="请输入您要搜索的供求订单关键字" @keyup.enter.native="toSupplySearch(false, supplySearchName)"></el-input>
                        <el-button type="primary" @click="toSupplySearch(false, supplySearchName)">搜索</el-button>
                    </div>
                    <div class="search_form" v-else>
                        <el-input v-model="supplySearchElseName" placeholder="请输入您要搜索的供求订单关键字" @keyup.enter.native="toSupplySearch(false, supplySearchElseName)"></el-input>
                        <el-button type="primary" @click="toSupplySearch(false, supplySearchElseName)">搜索</el-button>
                    </div>
                </div>

                <div class="car supply">
                    <el-button type="primary" plain @click="goAdminLogin(isLogin, status, '/supplyBuying/supply/add')"><img src="../../../assets/img/my_supply.png" />发布供应单</el-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
  import {goShopLogin, goAdminLogin} from '../../../utils/method'
  import {mapState} from "vuex";
  export default {
    name: "LayoutSearch",
    props: {
      searchDefaultData: {
        type: String,
        default: ''
      },
      searchHotData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        searchName: '',
        searchElseName: '',
        isSearchPage: false,
        searchType: 1,
        goShopLogin,
        goAdminLogin,
        isBuyingPage: false,
        buyingSearchName: '',
        buyingSearchElseName: '',
        isSupplyPage: false,
        supplySearchName: '',
        supplySearchElseName: ''
      }
    },
    computed: {
      ...mapState({
        status: state => state.user.userStatus,
        isLogin: state => state.user.isLogin,
        isMenu: state => state.user.isMenu,
        goodKeyword: state => state.user.goodKeyword,
        buyingKeyword: state => state.user.buyingKeyword,
        supplyKeyword: state => state.user.supplyKeyword,
      })
    },
    watch: {
      $route () {
        this.getRouter()
      }
    },
    created() {
      this.getRouter()
    },
    methods: {
      getRouter() {
        if (this.$route.path === '/shop/search') {
          this.searchName = this.goodKeyword
          this.isSearchPage = true
        } else {
          this.isSearchPage = false
        }

        if (this.$route.path === '/shop/buying') {
          this.buyingSearchName = this.buyingKeyword
          this.isBuyingPage = true
        } else {
          this.isBuyingPage = false
        }

        if (this.$route.path === '/shop/supply') {
          this.supplySearchName = this.supplyKeyword
          this.isSupplyPage = true
        } else {
          this.isSupplyPage = false
        }

        this.searchType = this.$route.meta.searchType
      },
      toGoodSearch (isPage, value) {
        if (!isPage) {
          this.$router.push({path: '/shop/search'})
          this.searchElseName = ''
        }
        this.$store.commit('changeGoodKeyword', value)
        this.searchName = this.goodKeyword
      },
      toBuyingSearch (isPage, value) {
        if (!isPage) {
          this.$router.push({path: '/shop/buying'})
          this.buyingSearchElseName = ''
        }
        this.$store.commit('changeBuyingKeyword', value)
      },
      toSupplySearch (isPage, value) {
        if (!isPage) {
          this.$router.push({path: '/shop/supply'})
          this.supplySearchElseName = ''
        }
        this.$store.commit('changeSupplyKeyword', value)
      }
    }
  }
</script>
